.mini-banner-parah{
    width: 80% !important;
}

.header-about-inner{
    background-image: url('../../assets/images/bg-small-banner-about.jpg') !important;
    position: relative;
    padding: 140px 0 40px !important;
}

.header-about-inner .black-overlay{
    background: #4e2f00b0 !important;
}

.team-card-container-indi{
    width: 100% !important;
}

.team-individual-container-wrapper .team-card-container {
    width: 36%;
}

.team-individual-container-wrapper .team-card-container img{
    width: 100%;
}

.team-individual-container-wrapper .img-main-left img{
    width: 100%;
    height: 43rem;
    object-fit: cover;
}

.team-individual-container-wrapper .team-left-content {
    width: 55%;
}


.meet-our-team-on-page{
    background: #b9a98c !important;
    padding: 90px 0 60px;
}

.banner-block-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.banner-block-text h2{
    color: #fff;
}

.banner-block-text p{
   width: 100%;
   text-align: left;
   color: #fff;
}

.team-individual-container-wrapper .team-left-content p{
    margin-bottom: 20px;
    margin-top: 0px;
}

.team-individual-container-wrapper .team-left-content p.highlited-text{
    color: #C79A4F;
    margin: 0;
    font-size: 17px;
    padding-left: 28px; 
    position: relative; 
    margin-bottom: 17px;
}

.team-individual-container-wrapper .team-left-content p.highlited-text:after{
    content: '';
    position: absolute;
    left: 0;
    top: 11px;
    width: 18px;
    height: 1px;
    background: #C79A4F;
}

.banner-block-text h2{
    font-weight: 700;
}

.banner-block-text h2::after{
  margin: unset;
}

.team-card-container-indi .card{
    border-radius: 7px;
    border: none;
}

.team-card-container-indi .card .content{
    border-radius: 0;
}

.testimonial-section{
    padding: 100px 0;
}

.teastimonial-wrapper{
    margin-top: 60px;
}

.meet-our-team-btn-about{
    margin-top: 20px;
}

.meet-our-team-on-page-indiv{
    background: #fffaf1 !important;
}

.meet-our-team-on-page-indiv .second-sec-txt-wrap{
    text-align: left;
}

.meet-our-team-on-page-indiv .second-sec-txt-wrap h2::after{
    display: none;
}

.meet-our-team-on-page-indiv .second-sec-txt-wrap p{
    width: 100%;
    margin-top: 0px;
}

.meet-our-team-on-page-indiv .team-card-container-indi{
    justify-content: flex-start;
    padding: 50px 0;
    margin: 0;
}

.meet-our-team-on-page-indiv .team-card-container-indi{
   gap: 30px;
}

.meet-our-team-on-page-indiv .team-card-container-indi .card{
   margin: 0;
}

.meet-our-team-on-page-indiv .team-card-container .card{
    width: 25%;
    height: 490px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
}

.meet-our-team-on-page-indiv .team-card-container .card .contentBx{
    margin-top: 10px;
}

.meet-our-team-on-page-indiv .team-card-container .card .contentBx h3{
    font-size: 22px;
    letter-spacing: 1px;
}

.meet-our-team-on-page-indiv .team-card-container .card .contentBx h3 span{
    color: #C79A4F;
    font-size: 16px;
}

.meet-our-team-on-page-indiv .team-card-container .card .content{
   background: #fff;
}

.team-card-container .card .content .contentBx h3{
    color: #000;
}


