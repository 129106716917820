.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-main{
  width: 100%;
  max-width: 98%;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .container-main {
    max-width: 100%;
    padding: 0 10px;
  }

  .team-left-content, .form-wrapper form, .bookapointment-form-input-sec input, .newsletter-form, .newsletter-form input, .newsletter-form button, .team-card-container .card img, .form-find-wrapper-left, .team-card-container, .team-card-container .card, .team-card-container .imgBx, .accomplisment-page-imges .images-cnd, .accomplisment-page-imges img, 
  .featured-listing-page-left-column .similar-properties .similar-listings-wrapper .property-listing-wrapper .property-thumb, .property-discription-part, 
  .single-page-middle-left .signle-page-main-img, .single-page-middle-left .signle-page-thumb-wraper{
    width: 100% !important;
  }

  .featured-listing-page-left-column .similar-properties .similar-listings-wrapper .property-listing-wrapper .property-thumb{
    margin-bottom: 20px;
  }

  .team-card-container .card{
    margin: 20px 0 !important;
  }

  .bookapointment-form-input-sec input:first-child{
    margin-bottom: 20px;
  }

  .accordion .accordion-button{
    gap: 10px;
    font-size: 14px !important;
  }

  .team-card-container, form .btm-input-sec{
    flex-wrap: wrap;
  }

  .hero-home-cnt h1 {
    width: 95% !important;
    font-size: 32px !important;
  }

  .property-thumb {
    width: 100% !important;
  }

  .accordian-radio-wraper, .team-container-wrapper{
    flex-wrap: wrap;
  }

  .nav-top-bar {
    background: #0a0a0ad6 !important;
  }

  .navbar-toggler-icon{
    filter: brightness(12);
  }

  .navbar-toggler:focus{
    box-shadow: none !important;
  }

  .logo-flg--wrapper img {
    width: 5rem !important;
  }

  .navbar-brand .logo-flg--wrapper{
    padding: 5px 0px 9px !important;
    border: none !important;
    background: none;
  }

  .navbar-brand .nav-right-top img{
    width: 7rem;
  }

  .bg-video-hero{
    background-image: url('./assets/images/mobile-hero-img.png');
    height: 100vh !important;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-size: cover;
    background-position: center;
  }

  .player{
    display: none;
  }

  .bg-video-hero .black-overlay {
    background: #0000005e;
  }

  .nav-items-wrapper{
    flex-wrap: wrap;
  }

  #basic-navbar-nav {
    width: 100% !important;
    height: 100vh;
    transition: all 0.3s;
  }

  .nav-left-top{
    margin-left: 0 !important;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .nav-left-top .nav-item.dropdown{
    margin: 0 auto;
    width: 100%;
  }

  .nav-left-top .nav-item.dropdown .dropdown-menu.show{
    width: 100%;
    text-align: center;
    padding: 24px 0;
  }

  a.nav-link{
    width: 100%;
  }

  .nav-top-bar.scrolled{
    background: #000 !important;
  }

  .second-sec-txt-wrap h2{
    font-size: 30px !important;
  }

  .bookappointment-block {
    padding: 38px 0 !important;
    border-radius: 22px !important;
  }

  .bookappontment-container{
    max-width: 100% !important;
  }

  .book-apointment-top-txt h2 {
    font-size: 34px !important;
    width: 90%;
    margin: 0 auto;
  }

  .book-apointment-top-txt p {
    width: 90% !important;
    margin: 14px auto 0 !important;
  }

  .bookapointment-form form {
    width: 95% !important;
  }

  .instafeeds-follow-right .instafeeds-follow-right-cnt{
    flex-direction: column;
    justify-content: center;
    gap: 14px;
  }

  .instafeeds-follow-right .instafeeds-follow-right-cnt p{
    margin: 0 !important;
  }

  .footer-main .footer-bar .footer-bar-cnt p {
    font-size: 12px !important;
    width: 100% !important;
    font-weight: 300 !important;
  }

  .newsletter-wrapper{
    flex-direction: column;
    margin: 30px 0 !important;
  }

  .footer-btm-nav-sec-grey {
    padding: 40px 10px !important;
    gap: 30px !important;
    margin: 0 auto !important;
  }

  .footer-top-area-blck, .footer-btm-area-clr{
    margin: 0 auto !important;
  }

  .footer-logo{
    text-align: center !important;
  }

  .newsletter-form{
    flex-wrap: wrap;
    gap: 9px !important;
  }

  .footer-top-area-blck .row{
    margin: 0 auto;
  }

  .accomplishment-slides-mobile{
    display: block !important;
  }

  .accomplishment-slides-dsktp{
    display: none !important;
  }

  .carasaul-img-wrapper img.logo-awrd {
    width: 50% !important;
  }

  .instafeeds-wrapper {
    padding: 50px 0 0 !important;
  }

  .footer-top {
    padding: 50px 0 0 !important;
  }

  .form-find-wrapper-right{
    display: none;
  }

  .footer-bar-cnt{
    flex-direction: column !important;
    gap: 10px !important;
  }

  .testinomials .carousel-indicators{
    margin: 0;
  }

  .team-left-content h2 {
    font-size: 32px;
    margin-top: 60px;
  }

  .img-main-left{
    margin-bottom: 0 !important;
  }

  .meet-our-team-btn-about {
    display: none !important;
  }

  .team-container-wrapper{
    gap: 0 !important;
  }

  .about-sec-middle-sec{
    flex-direction: column-reverse;
  }

  .productlisting-wrapper .property-bottom-cnt-change-wrapper .property-thumb{
    width: 100% !important;
  }

  .property-filter-bar-wrapper{
    flex-direction: column;
    gap: 24px;
    margin-top: 10px !important;
  }

  .carasaul-img-wrapper img{
    height: 20rem !important;
    object-fit: contain;
    width: 70%;
  }

  .accomplisment-page-imges{
    flex-wrap: wrap;
  }

  .featured-listing-page-two-columns .container-main{
    flex-direction: column;
  }

  .featured-listing-page-left-column {
    width: 100% !important;
  }

  .featured-listing-page-right-column {
    width: 100% !important;
    margin-bottom: 30px !important;
  }

  .listing-indi-page .featured-listing-page-left-column .productlisting-wrapper .property-thumb {
    width: 100% !important;
  }

  .property-bottom-cnt-change-wrapper{
    margin-bottom: 0 !important;
  }

  .listing-indi-page .banner-block-text p{
    width: 100% !important;
  }

  .featured-listingcolumn-top{
    margin-top: 0px !important;
  }

  .property-filter-bar .featured-listingcolumn-top.featured-listingcolumn-top-left{
    margin-top: 20px !important;
  }

  .single-page-top-bar-wrapper, .propert-details-se-wrp, .walking-and-transportaion-wrapper .walking-and-transportaion-point-wrapper{
    flex-direction: column;
  }

  .productlisting-wrapper.similar-listings-wrapper{
   padding-bottom: 0;
  }

  .propert-details-se-wrp{
    gap: 0 !important;
  }

  .inner-map-sec h5 {
    font-size: 25px !important;
  }

  .signle-page-top-bar{
    padding: 100px 0 20px !important;
  }

  .property-discription-part-wrapper{
    flex-wrap: wrap;
  }
  
  .single-page-middle-left{
    flex-wrap: wrap;
  }

  .carousel-item .slider-images{
    flex-wrap: unset !important;
    margin-top: 2px;
  }

  .signle-page-thumb-wraper img{
    width: 33.3% !important;
    height: 104px !important;
  }

  .signle-page-top-bar-left-btm{
    flex-direction: column !important;
    width: 100% !important;
    gap: 5px !important;
    align-items: flex-start !important;
  }

  .signle-page-top-bar-right{
    gap: 10px !important;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .signle-page-top-bar-right .property-address-single{
    width: 48% !important;
    flex-direction: unset !important;
  }

  .breadcrums-wrapper{
    display: none !important;
  }

  .back-btn-page button{
    font-size: 16px !important;
  }

  .back-btn-page button img{
    width: 1.4rem !important;
  }

  .modal-full-img .modal-content .modal-body .slick-slide img{
    height: 57vh !important;
  }

  .modal-full-img {
    margin-top: 190px !important;
  }

  .propert-details-se-wrp .propert-details-sec {
    columns: 1;
  }

  .meet-our-team-on-page-indiv .team-card-container .card{
    height: 515px !important;
  }
}

@media screen and (min-width: 1500px) {
  .footer-nav-link a.nav-link {
    font-size: 15px !important;
  }
 
  .carasaul-img-wrapper img{
    height: 26rem !important;
  }

  .carasaul-img-wrapper img.logo-awrd{
    height: auto !important;
  }

  .form-find-wrapper-left {
    width: 55% !important;
  }

  .form-find-wrapper-right {
    width: 44% !important;
  }

  .container-main-about-sec-tw{
   max-width: 1440px !important;  
  }

  .meet-our-team-on-page-indiv .container-main{
    max-width: 1440px !important;
  }
} 
