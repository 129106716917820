  .nav-items-wrapper{
    display: flex;
    justify-content: space-between;
  }

  #basic-navbar-nav{
    width: 75%;
    flex-basis: unset;
    flex-grow: 0;
  }
  
  .nav-top-bar{
    background: #0a0a0a4f;
    padding: 0 !important;
    position: fixed !important;
    width: 100%;
    z-index: 99;
    transition: .3s ease-in-out all;
    top: 0;
  }

  .nav-top-bar.scrolled{
    position: fixed !important;
    top: 0;
    background: #000000b8;
    z-index: 999;
  }

  .nav-top-bar.scrolled .logo-flg--wrapper{
      border: none;
      padding: 8px 10px !important;
  }
  
  .navbar-brand{
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    gap: 20px;
  }

  .logo-flg--wrapper{
    border-bottom: 2px solid #C79A4F;
    background: rgb(0 0 0 / 91%);
    margin: 0 !important;
    padding: 10px 18px !important;
    transition: .3s ease-in-out all;
  }

  .logo-flg--wrapper img{
    width: 6rem;
  }
  
  a.nav-link{
    color: #fff !important;
    padding: 0 !important;
    letter-spacing: 1.8px;
    text-transform: none;
    font-weight: 500 !important;
    font-size: 16px !important;
    text-decoration: none;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  
  .nav-div-right-wrapper{
    width: 100%;
    justify-content: end;
    gap: 50px;
  }
  
  .nav-left-top{
    display: flex;
    align-items: center;
    gap: 25px;
    font-size: 15px;
    margin-left: 130px;
  }
  
  .nav-right-top{
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 0 !important;
  }

  .nav-right-top .nav-link{
    border-left: 1px solid #ffffff;
    padding-left: 30px !important;
  }

  .nav-right-top img{
    margin-top: -6px;
    margin-right: 0;
    margin-left: auto;
  }

  .dropdown-menu.show a{
    padding: 2px 16px !important;
  }

  .dropdown-menu.show{
    background: #000;
    min-width: unset;
    max-width: unset;
    width: 12rem;
    border-radius: 0;
    margin-top: 13px;
  }

  .dropdown-menu.show .dropdown-divider{
    border-color: #ffffff24 !important;
  }

  .nav-item.dropdown .dropdown-menu{
    background: #c79a4fa8;
  }

