.productlisting-wrapper{
    padding: 90px 0;
}

.property-listing-wrapper{
    margin-top: 50px;
    display: flex;
    width: 100%;
    gap: 1%;
    flex-wrap: wrap;
    row-gap: 28px;
}

.property-thumb{
    width: 32.6%;
    border-radius: 4px;
    background: #212121;
    padding: 0;
    position: relative;
}

.property-thumb a, .property-top-img, .property-img{
    width: 100%;
}

.property-top-img{
    position: relative;
}

.property-thumb a{
    text-decoration: none;
}

.property-img{
    height: 28rem;
    object-fit: cover;
    width: 100%;
    border-radius: 2px;
}

.property-bottom-cnt{
    margin-top: 15px;
    text-align: left;
    padding: 10px 15px 0px;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #000000c7;
}

.property-bottom-cnt h5{
    color: #FFF;
    font-size: 24px;
    font-weight: 500;
}

.property-address{
    color: #ffffffe3;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 7px;
}

.property-status{
    background: #000;
    color: #fff;
    font-size: 15px;
    padding: 2px 20px;
    margin-left: -15px;
    /* clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%); */
    /* width: 15%; */
    display: block;
    position: absolute;
    top: -26px;
}

.property-status.active{
    background: #1A8B17;   
}

.property-status.sold{
    background: #A91212;   
}

.property-btm-config-wraper{
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
}

.property-btm-config-wraper .property-btm-config .property-config-icon{
    display: flex;
    align-items: center;
    gap: 6px;
}

.property-btm-config-wraper .property-btm-config p{
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
}

.open-property-modal{
    position: absolute;
    top: 20px;
    right: 20px;
    background: #c79a4fed;
    border: 1px solid #C79A4F;
    border-radius: 2px;
    color: #fff;
    font-size: 15px;
    padding: 6px 14px;
    z-index: 99;
}

.show-on-popup-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    background: #000000d6;
    width: 100%;
    height: 100%;
    padding: 24px;
    z-index: 99;
}

.show-on-popup-wrapper .show-on-popup{
    width: 100%;
    background: #ffffffe0;
    height: 100%;
    position: relative;
    padding: 20px;
    border-radius: 4px;
}

.show-on-popup-wrapper .show-on-popup button{
    position: absolute;
    top: 4px;
    right: 4px;
    width: 30px;
    height: 30px;
    background: #c79a4feb;
    color: #fff;
    border: 1px solid #c79a4f;
    border-radius: 2px;
}

.show-on-popup-wrapper .show-on-popup .openhouse-popup-content{
    margin-top: 40px;
}

.show-on-popup-wrapper .show-on-popup .openhouse-popup-content p{
    margin-bottom: 0;
    font-size: 17px;
    border-bottom: 1px solid #0000001f;
    padding: 10px 0;
    text-align: left;
}

.show-on-popup-wrapper .show-on-popup .openhouse-popup-content p:last-child{
    border-bottom: none;
}