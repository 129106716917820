.single-page-wrapper{
  padding-top: 0px;
  position: relative;
}

.signle-page-top-bar{
    width: 100%;
    margin-top: 0px;
    background: rgb(48 48 48);
    padding: 130px 0 20px;
    border-bottom: 2px solid #fffdfd;
}

.back-btn-page{
  text-align: left;
}

.back-btn-page button{
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 20px;
}

.back-btn-page button:hover{
  background: none;
}

.back-btn-page button img{
  filter: invert(1);
  width: 1.5rem;
}

.property-status-single-page{
  margin-left: 0;
  text-align: left;
  padding: 3px 12px;
  font-size: 15px;
  position: relative;
  top: 0;
  border-radius: 50px;
}

.property-mls-no{
    text-align: left;
    color: #2c2c2c;
    font-size: 32px;
    font-weight: 600;
    margin-top: 10px;
    letter-spacing: 1px;
}

.individual-page-top-two{
  border-top: 1px solid #00000029;
  padding: 12px 0 20px;
}

.property-address-single{
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #1c1c1c;
    font-size: 18px;

    img{
      margin-bottom: 2px;
      filter: invert(1);
    }
}

.signle-page-top-bar-right-icons img{
    opacity: 0.8;
}

.signle-page-top-bar-left-btm{
  display: flex;
  align-items: center;
  gap: 30px;
}

.signle-page-top-bar-left-btm p, .signle-page-top-bar-left-btm h3{
  margin: 0;
}

.signle-page-top-bar-left-btm h3{
  font-size: 25px;
}

.signle-page-top-bar-right .property-address-single{
  flex-direction: column;
  color: #565656;
}


.single-page-top-bar-wrapper{
    display: flex;
    justify-content: space-between;
}

.signle-page-top-bar-right{
    display: flex;
    align-items: end;
    gap: 60px;
}

.property-share-like-wrapper{
    display: flex;
    gap: 10px;
}

.signle-page-middle-wrapper{
    display: flex;
    justify-content: space-between;
}

.single-page-middle-left{
    width: 100%;
    display: flex;

    .signle-page-main-img{
      width: 40%;
      position: relative;
    }

    .signle-page-thumb-wraper{
      width: 60%;
      margin-top: 0;
      background: #e1e1e1;
      
      .carousel.slide{
          width: 100%;
      }
    }
}

.signle-page-main-img{
  img{
    width: 100%;
    height: 510px;
    object-fit: cover;
  }
}

.signle-page-thumb-wraper{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;

  img{
    width: 24.8%;
    object-fit: cover;
    cursor: pointer;
    height: 254px;
  }

  .carousel{
    .carousel-control-prev{
      width: auto;
      .carousel-control-prev-icon{
        background-color: #000000b0;
        padding: 0;
        width: 3rem !important;
        height: 3rem !important;
      }
    }

    .carousel-control-next{
      width: auto;
      .carousel-control-next-icon{
        background-color: #000000b0;
        padding: 0;
        width: 3rem !important;
        height: 3rem !important;
      }
    }
  }
}

.single-pageover-sec{
   padding: 110px 0;
   background: #000;
  background: linear-gradient( 90deg, rgb(0, 0, 0) 50%, #212121 50%);
}

.property-overview-top-bar{
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ffffffc7;
  margin-bottom: 32px;

  h4{
    color: #C79A4F;
    font-size: 20px;
    font-weight: 600;
  }

  h5{
    color: #C79A4F;
    font-size: 16px;
    font-weight: 500;
  }
}

.single-pageover-wrapper{
  display: flex;
  justify-content: space-between;
  gap: 120px;

  .single-pageover-left, .single-pageover-right {
    width: 100%;
  }

  .single-pageover-left .property-config-icon p{
    color: #C79A4F;
    font-weight: 600;
  }

  .property-config-icon-wht{
    p{
      font-weight: 500 !important;
    }

    img{
      margin-bottom: 3px;
    }
  }


  .single-pageover-right {
      text-align: left;
       h4{
        color: #000;
        background: #C79A4F;
        margin-bottom: 40px;
        padding: 10px 20px;
        font-size: 22px;
        font-weight: 500;
       }

       p{
        color: #fff;
        font-size: 14px;
            strong{
              font-weight: 400;
            }
       }
  }
}

.single-pageover-left-clm{
  margin-bottom: 40px;
}


.single-page-middle-right{
  width: 31%;
}

.single-page-right-form{
  width: 100%;
  background: #fff;
  border-radius: 15px;
  padding: 0px;
}

.listing-form-top-sec{
  margin-top: 30px;
  background: none !important;
  padding: 0 !important;

  .listing-form-top-cnt{
    width: 100%;
    display: block;
    h6{
      text-align: center;
      font-family: "Poppins", sans-serif !important;
      font-size: 14px !important;
      font-weight: 400 !important;
    }
  }
}

.listing-form-top{
  border-radius: 10px;
  background: #F5F5F5;
  padding: 12px 15px;
  display: flex;
  gap: 20px;
  align-items: center;

  .listing-form-top-cnt{
    text-align: left;

        h6{
          font-size: 16px;
          font-weight: 500;
          color: #000;
          margin-bottom: 0;
        }

        a{
          color: #C79A4F;
          font-size: 14px;
          text-decoration: none;
          font-weight: 500;
        }
  }
}

.avatar{
  width: 43px;
  height: 43px;
  border-radius: 50%;
  object-fit: cover;
}

.listing-form-btn{
  width: 100%;
  display: flex !important;
  align-items: center;
  gap: 15px;
  border: none !important;
  border-radius: 10px !important;
  background: #000 !important;
  padding: 16px 27px !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  color: #fff !important;
  justify-content: space-between;
}

form.listing-form{
  margin-top: 16px;

  input, textarea, select{
    border: 1px solid #DBDBDB;
    color: #000;
    padding: 13px 20px;
    font-size: 14px;
    ::placeholder{
      opacity: 1;
      color: #000;
    }
  }
}


.listing-form-check {
  margin-top: 20px;
    label{
      font-size: 13px;
      color: #000;
    }
}

.listing-form-check .form-check{
  text-align: left;
}

.carousel-item {
  .slider-images{
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    margin-left: 2px;
  }
}

.property-description-wrap{
  margin-top: 57px;
  text-align: left;
  font-size: 13px;
}

.featured-listing-page-two-columns{
  margin-bottom: 60px;
}

.property-discription-part-wrapper{
  display: flex;
  border-bottom: 1px solid #00000047;
  padding-bottom: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.property-discription-part-wrapper-next{
  border: none;
}

.single-page-discription p{
  color: #545454;
}

.property-discription-part{
  display: flex;
  gap: 10px;
  align-items: center;
  width: 33.3%;
}


.property-discription-part{
  p{
    margin-bottom: 0;
    color: #545454;
  }

  .big{
    font-weight: 600;
    font-size: 18px;
  }
}

.similar-listings-wrapper{
   .second-sec-txt-wrap h2{
       text-align: left;
       font-size: 27px;
   }

   .second-sec-txt-wrap h2::after{
       margin: 0;
   }
}


.similar-properties .similar-listings-wrapper .property-listing-wrapper {
  gap: 1.1%;
  margin-bottom: 0;
}

.similar-properties .similar-listings-wrapper .property-listing-wrapper .property-thumb{
  width: 32.6% !important;

  .property-img{
    height: 18rem;
  }
}

.modal-dialog{
  max-width: 740px !important; 
}

.all-form-meterial{
  padding: 0 30px 30px;
}

.form-half-column-wrapper{
  display: flex;
  gap: 20px;
}

.form-half-column{
  width: 100%;
}

.listing-form-top-cnt{
  display: flex;
  justify-content: space-between;
  width: 100%;

  button{
    padding: 0;
    border: none;
    background: none;
    font-size: 23px;
  }
}

.listing-page-top-bar-bread-crums{
  display: flex;
  justify-content: space-between;

  .breadcrums-wrapper{
    display: flex;
    gap: 12px;
    .breadcrum-txt{
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        line-height: 28px;
    }

    p{
      color: #fff;
      margin-bottom: 0;
    }
  }
}

.walking-and-transportaion-wrapper{
  .productlisting-wrapper.similar-listings-wrapper{
     padding-bottom: 0;
  }
  .walking-and-transportaion-point-wrapper{
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 40px;

    .walking-and-transportaion-point{
      background: #f5f5f4;
      padding: 20px;
      border-radius: 10px;
      width: 100%;
      border: 1px solid #c7c7c7;

      h5{
        font-size: 20px;
        font-weight: 800;
        color: #c79a4f;
        margin-bottom: 0;
        margin-top: 10px;
      }

      .icon-with-text{
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        
        img{
          width: 5rem;
          opacity: 0.6;
        }

        h6{
          font-size: 80px;
          font-weight: 500;
          color: #c79a4f;
          margin-bottom: 0;
        }
      }
    }
  }
}

.schools-parks-wrapperr{
  padding-top: 0px !important;
  .productlisting-wrapper{
    padding-top: 70px;
  }
}

.schools-parks-wrapper{
  border-bottom: 1px solid #0000001a;

  .walking-and-transportaion-point{
    border: none !important;
    background: none !important;
    padding: 0 0 40px !important;

    p{
      line-height: 21px;
      margin-top: 20px;
    }

    .icon-with-text{
      img{
        opacity: 0.8 !important;
      }
    }
  }
}

.about-property{
  margin-top: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1019607843);
  border-top: 1px solid rgba(0, 0, 0, 0.1019607843);
  padding-top: 40px;
  padding-bottom: 40px;

  h5{
    font-size: 34px;
    color: #545454;
    font-weight: 400;
  }

  p{
    color: #545454;
    margin-top: 20px;
    font-weight: 400;
  }
}

.inner-map-sec{
  margin-top: 40px;
  h5{
    font-size: 34px;
    color: #000;
    font-weight: 400;
  }
}

.infonew-table{
  margin-top: 10px;
  p:last-child{
    border: none;
  }
  p{
    border-bottom: 1px solid rgb(0 0 0 / 7%);
    padding: 9px 0;
    color: #545454;
    margin-bottom: 0;
  }

  span{
    color: #545454;
    font-weight: 600;
    margin-right: 5px;
  }
}

.propert-details-se-wrp{
  .propert-details-sec{
    columns: 2;
    gap: 60px;
    margin-top: 40px;

    .inner-map-sec{
      margin-top: 0;
      margin-bottom: 30px;
    }
  }

  .inner-map-sec{
    width: 100%;
    display: inline-block;
  }
}

.modal-full-img{
  max-width: 98% !important;
  height: 80vh;

  .slick-dots{
    display: none !important;
  }
  .modal-content{
    border-radius: 0;
    padding: 20px;
    background: rgb(255 255 255 / 88%);

    .modal-body{
      padding: 0;
      .slick-slide img{
        height: 87vh;
        object-fit: contain;
      }

      .slick-prev{
        background: #000;
        width: 60px;
        height: 60px;
        border-radius: 5px;
        z-index: 99;
      }

      .slick-next{
        background: #000;
        width: 60px;
        height: 60px;
        border-radius: 5px;
        z-index: 99;
      }
    }
  }
}

.full-img-cross{
  width: 50px;
  height: 50px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 99;
  background: #000;
  border: none;
  color: #fff;
  font-size: 25px;
}

.property-auth{
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: 1px;
  cursor: pointer;
  flex-direction: column;

  img{
    width: 7rem;
    height: 100px;
    object-fit: cover;
  }

  p{
    margin-bottom: 0;
  }
}

