  .testinomials{
    width: 100%;
  }

  .testinomials .carousel-indicators{
    display: flex !important;
    bottom: -55px;
  }

  .testinomials .testimonial_head h2,h3{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 7.5px;
    color: #fff;
    position: relative;
  }
  
  .testinomials .testimonial_head p{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 7.5px;
    color: #fff;
    position: relative;
    padding-left: 30px;
  }
  
  .testinomials .testimonial_head p:before{
     content: "";
      width: 18px;
      height: 2px;
      background: #D9D9D9;
      position: absolute;
      top: 11px;
      left: 1px;
  }
  
  .testinomials .testimonial_head h2 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 45px;
    color: #FFFFFF;
    letter-spacing: 1px;
    margin-bottom: 110px;
  }
  .testimonial_head 
   .carousel-inner {
      overflow: visible;
  }
  .testinomials .card {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 30px 20px;
    display: flex !important;
    flex-direction: row !important;
  }

  .testinomials .card-body {
     padding-right: 0 !important;
  }

  .testinomials img.card-img-top {
    border: 1px solid #FFAC33;
    border-radius: 2px;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
  .testinomials .card-body h6 {
    font-style: italic;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #232E35;
    text-align: left;
  }
  .testinomials .card-body h6:before,
  .testinomials .card-body h6:after{
    content: '"';
  }
  .testinomials .carousel-indicators button {
    opacity: 1 !important;
  }
  .testinomials .carousel a.carousel-control-prev,
  .testinomials .carousel a.carousel-control-next {
    display: none;
  }
  .testinomials .carousel-indicators .active {
    opacity: 1;
    background-color: #000 !important;
  }
  .testinomials .card-body h4 {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #232E35;
    text-align: left;
    margin-top: 10px;
  }