.heade-for-inner-listing{
    padding: 230px 0;
    background-image: url('../../assets/images/bg-small-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.heade-for-inner-listing .black-overlay{
    background: #00000031;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}

.inner-header-cntnt{
    position: relative;
    z-index: 9;
}

.listing-indi-page .productlisting-wrapper{
  padding: 0;
}

.property-bottom-cnt-change{
    position: unset;
    padding: 0;
    margin: 0 !important;
    border: 1px solid #50505024;
    background: #fff;
}

.property-bottom-cnt-change .property-rate{
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0,212,255,0) 100%);
    padding: 16px 20px 8px;
    font-weight: 600;
    text-align: left;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    width: 100%;
}

.property-bottom-cnt-change .property-btm-config-wraper{
    margin: 0;
    padding: 16px 20px;
    border-top: 1px solid #0000001f;
}

.property-bottom-cnt-change .property-btm-config-wraper .property-config-icon img{
    opacity: 0.7;
}

.property-bottom-cnt-change .property-btm-config-wraper .property-config-icon p{
    font-size: 20px;
    font-weight: 600;
    color: #606060;
}

.property-bottom-cnt-change .property-btm-config{
    display: flex;
    align-items: center;
    gap: 3px;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
}

.property-bottom-cnt-change-wrapper{
    gap: 3.5%;
    row-gap: 50px;
    margin-top: 30px;
    margin-bottom: 70px;
}

.property-bottom-cnt-change-wrapper .property-top-img{
    /* padding: 10px 20px; */
}

.property-bottom-cnt-change-wrapper .property-top-img .property-img{
    height: 17rem;
}

.property-bottom-cnt-change .property-status{
    top: 20px;
    margin-left: 0px;
}

.property-bottom-cnt-change .property-address{
    color: #646464;
    padding: 0px 16px 19px; 
    margin-bottom: 0; 
    font-weight: 400;
}

.property-bottom-cnt-change-wrapper .property-thumb {
    width: 31% !important;
}

.property-filter-bar .property-filter-bar-wrapper{
    margin-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.property-filter-bar .property-filter-bar-wrapper .counting-filter p{
    margin-bottom: 0;
    font-size: 14px;
}

.property-bottom-cnt-change .property-btm-config-wraper .property-btm-config p{
    color: #484747;
    font-size: 14px;
}

.property-bottom-cnt-change span.property-config-icon img{
    filter: invert(1);
    opacity: 0.8;
}

.property-filter-bar .property-filter-bar-wrapper .tab-filter{
    display: flex;
    gap: 15px;
    margin: 0 auto 0 30px;
}

.property-filter-bar .property-filter-bar-wrapper .tab-filter button{
    background: #202020;
    border: 1px solid #ffffff4f;
    padding: 4px 30px;
    color: #fff;
}

.property-filter-bar .property-filter-bar-wrapper .tab-filter button.active{
    background: #000;
    border: 1px solid #c79a4f;
}

.property-filter-bar .property-filter-bar-wrapper .tab-select{
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #000;
    padding: 0px 10px;
    text-align: left;
}

.listing-indi-page .banner-block-text p{
    width: 67%;
    margin: 29px 0 0;
    line-height: 20px;
    letter-spacing: 0.5px;
}


.property-filter-bar .property-filter-bar-wrapper .tab-select p{
     margin-bottom: 0;
}

.featured-listing-page-two-columns .container-main{
    display: flex;
    gap: 60px;
}

.featured-listing-page-left-column{
    width: 70%;
}

.featured-listing-page-left-column .productlisting-wrapper .property-thumb{
    width: 48.2% !important;
}

.featured-listing-page-right-column{
    width: 30%;
}

.featured-listingcolumn-top{
    border: 1px solid #c3c3c3;
    margin-top: 60px;
}

.featured-listingcolumn-header{
    background: #ededec;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 20px;
}

.featured-listingcolumn-header-center{
    justify-content: center;
    background: #fff;
    gap: 15px;
    padding-top: 38px;
}

.featured-listingcolumn-footer-wrapper-btn{
    padding: 22px 20px 40px !important;
}

.featured-listingcolumn-footer-wrapper-btn button.ask-question-btn{
    margin-top: 14px;
    background: #C79A4F;
    border: none;
    color: #fff;
    padding: 10px 30px;
    border-radius: 4px; 
}



.featured-listingcolumn-footer-search input{
    width: 100%;
    background: #fff;
    padding: 8px;
    border: 1px solid #00000036;
    font-weight: 400;   
}

.featured-listingcolumn-footer-search button{
    background: #000 !important;
    width: 100%;
}

.featured-listingcolumn-header-center h4{
    margin-bottom: 0;
}

.featured-listingcolumn-header-center img{
    width: 3rem;
}

.featured-listingcolumn-header h5, .featured-listingcolumn-header p{
    margin-bottom: 0;
}

.featured-listingcolumn-footer-wrapper{
    display: flex;
    padding: 15px 20px;
    gap: 20px;
    background: #fff;
}

.featured-listingcolumn-footer{
   width: 100%;
}

.featured-listingcolumn-footer h6{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 0;
    font-weight: 400;
    color: #0000008c;
}

.featured-listingcolumn-footer h6 span{
    font-size: 26px;
    color: #282828;
}

.property-address.mls-top{
    padding: 19px 16px 0;
    font-weight: 600;
    font-size: 20px;
}

.mls-search-drop{
    border: 1px solid #0000003b;
    margin-top: 2px;
}

.mls-search-drop p{
margin-bottom: 0;
padding: 8px 5px;
}

.mls-search-drop p a{
color: #000;
text-decoration: none;
font-size: 14px;
}
 