.instafeeds-wrapper{
    padding: 40px 0 0;
    background: #cdcdcd;
}

.btn-yellow{
    color: #000;
    background: #FFAC33;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
}

.insta-feeds-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 49px;
    flex-direction: column;
}

.instafeeds-left {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f1e9e9;
    padding: 50px 0;
    flex-wrap: wrap;
    gap: 5px;
}

.instafeeds-left .insta-feed {
    width: 19.5%;
    height: 200px;
}

.instafeeds-left .insta-feed img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.instafeeds-follow-right{
    width: 90%;
}

.instafeeds-follow-right .instafeeds-follow-right-cnt{
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.instafeeds-follow-right .instafeeds-follow-right-cnt h2{
    font-size: 42px;
    font-weight: 700;
    position: relative;
    padding-bottom: 0px;
    letter-spacing: 1.5px;
    margin-bottom: 0;
}

.instafeeds-follow-right .instafeeds-follow-right-cnt p{
    font-size: 13px;
    font-weight: 400;
    margin-bottom: -5px;
    margin-left: 30px;
    margin-right: auto;
}