.footer-main{
    background: #3a3a3a;
}

.footer-top{
    padding: 50px 0 0;
}

.footer-main .footer-bar{
    padding: 20px 0;
    background: #3a3a3a;
    padding: 45px 0;
}

.footer-main .footer-bar .footer-bar-cnt{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-main .footer-bar .footer-bar-cnt p{
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
}

.copyright-txt{
    width: 70%;
}

.footer-main .footer-bar .footer-bar-cnt p.developed_by a{
    color: #2c82cc;
    text-decoration: none;
}

.social-icons{
    display: flex;
    gap: 20px;
    justify-content: center;
}

.social-icons a{
    background: #fff;
    border-radius: 5px;
    padding: 8px;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icons a img{
    filter: invert(1);
    height: 1rem;
}

.quick-links{
    text-align: left;
    color: #fff;
}

.quick-links h4{
    text-align: left;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    position: relative;
}

.quick-links h4:after {
    content: '';
    position: absolute;
    width: 45px;
    height: 4px;
    background: #C79A4F;
    bottom: -15px;
    left: 0;
}

.footer-logo{
    text-align: left;
}

.footer-logo img{
    width: 7rem;
}

.footer-nav-link{
    margin-top: 40px;
}

.footer-nav-link2{
    padding-right: 40px;
}

.footer-nav-link a{
    font-weight: 300 !important;
    font-size: 14px !important;
    letter-spacing: 0.3px;
    width: 100%;
    margin-bottom: 11px;
    display: flex;
    gap: 14px;
    align-items: self-start;
}

.footer-nav-link a img{
    margin-top: 2px;
}

.newsletter-wrapper{
    display: flex;
    background: #161616;
    padding: 20px 19px;
    border-radius: 9px;
    gap: 20px;
    align-items: center;
}

.newsletter-wrapper h3{
    text-align: left;
    font-size: 22px;
    margin-bottom: 0;
    color: #fff;
}

.newsletter-form{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 80%;
}

.newsletter-form input{
    background: #fff;
    border: none;
    padding: 10px;
    font-size: 13px;
    border-radius: 4px;
    width: 60%;
}

.newsletter-form button{
    width: 37%; 
    padding: 14px 10px !important;
    font-size: 13px !important;  
}

.footer-btm-nav-sec-grey{
    padding-left: 40px;
}

.footer-btm-area-clr{
    background: #161616;
    margin-top: 50px !important;
}

.footer-btm-area-clr .map-iframe{
    height: 370px;
}