.bg-video-hero{
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -9;
}

.bg-video-hero .black-overlay{
    position: absolute;
    top: 0;
    width: 100%;
    background: #00000024;
    height: 100%;
    z-index: 99;
}

.hero-home-cnt{
    width: 100%;
}

.hero-home{
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: 99;
    width: 43rem;
}

.hero-home-cnt{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}

.kunal-area-hero{
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
}

.hero-home-cnt h1{
    text-align: center;
    font-size: 4rem;
    font-weight: 500;
    color: #fff;
    position: relative;
    margin: 0 auto;
    letter-spacing: 2px;
}

  .hero-home-cnt h1{
    transform: translateY(100%); /* Start the div off the screen */
    animation: slide-in .5s ease forwards;
  }
  
  @keyframes slide-in {
    0% {
        transform: translateY(100%); /* Start position off the screen */
    }
    100% {
        transform: translateY(0); /* End position at the bottom of the container */
    }
  }

.hero-home-cnt h1:after{
    content: '';
    position: absolute;
    width: 54px;
    height: 5px;
    background: #C79A4F;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.kunal-inf-tag-wrapper{
    position: absolute;
    right: -174px;
    bottom: 32px;
    border-left: 9px solid rgb(167 121 46 / 57%);
    padding-left: 9px;
}

.kunal-inf-tag{
    background: rgb(167 121 46 / 57%);
    text-align: left;
    padding: 6px 11px;
}

.kunal-inf-tag h6{
    color: #fff;
    margin: 0;
    font-weight: 700;
    font-size: 19px;
    letter-spacing: 1px;
}

.kunal-inf-tag p{
    color: #fff;
    margin: 0;
    font-weight: 300;
    font-size: 11px;
    letter-spacing: 1px;
}

.player{
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    margin-top: -12px;
}

.player iframe{
    height: 100vh;
}