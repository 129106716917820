.bookappontment-container{
    max-width: 80%;
}

.bookappointment-wrapper{
    /* background: #cdcdcd; */
    padding: 100px 0;
}

.bookappointment-block{
    background: #a17b3d;
    padding: 65px 0;
    border-radius: 42px;
    background-image: url('../assets/images/building-left.png'), url('../assets/images/building-right.svg') !important;
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: left bottom, right bottom;
}

.book-apointment-top-txt{
   width: 100%;; 
}

.book-apointment-top-txt h2{
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    position: relative;
    padding-bottom: 0px;
    letter-spacing: 1.5px;
}

.book-apointment-top-txt p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    width: 48%;
    margin: 18px auto 0;
}

.bookapointment-form{
    margin-top: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.bookapointment-form form{
    width: 50%;
    padding: 29px 23px;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: rgba(217, 217, 217, 0.17);
    backdrop-filter: blur(9px);
}

.bookapointment-form-input-sec{
    width: 100%;
    display: flex;
    gap: 3%;
    margin-bottom: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.bookapointment-form-input-sec input{
    width: 48.5%;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.50);
    border: none;
    padding: 11px 12px;
    font-size: 13px;
    color: #000;
    font-weight: 400;
}

.bookapointment-form-input-sec textarea{
    width: 100%;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.50);
    border: none;
    padding: 11px 12px;
    font-size: 13px;
    color: #000;
    font-weight: 400;
    height: 95px;
    margin-top: 17px;
}

.submit-wrapper-simp{
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-arrow-dark{
    display: flex;
    align-items: center;
    gap: 15px;
    border: none;
    border-radius: 4px;
    background: #000;
    padding: 11px 27px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}