.accomplishment{
    /* background: #1a1a1a; */
    padding: 150px 0;
    background-image: url('../assets/images/bbbg.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: bottom;
    position: relative;
}

.accomplishment-overlay{
    background: #0000008f;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.accomplishment-slides-mobile{
    display: none;
}

.accomplishment-slides{
    margin-top: 60px;
}

.accomplishment h2{
  color: #fff;
}

.carousel-indicators{
    display: none !important;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
    background: #A7792E;
    border-radius: 5px;
    width: 40px !important;
    height: 40px !important;
    background-position: center;
    color: #fff;
    background-size: 50% !important;
}

.carousel-control-prev, .carousel-control-next{
    opacity: 1 !important;
}

.carasaul-img-wrapper{
    display: flex;
    gap: 40px;
    justify-content: center;
    height: 30rem;
    align-items: center;
}

.carasaul-img-wrapper img{

    height: 24rem;
}

.carasaul-img-wrapper img.logo-awrd{
    width: 15%;
    height: auto;
}

.acoomplisment-page-awwards{
    position: relative;
    padding: 60px 0 0;
}

.accomplisment-page-imges-wrapper{
    margin: 60px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
}

.accomplisment-page-imges{
    width: 100%;
    display: flex;
    gap: 40px;
    justify-content: center;
}

.accomplisment-page-imges-center .images-cnd{
    width: 14rem;
}

.accomplisment-page-imges-center .images-cnd img{
    width: 100%;
}