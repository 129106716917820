.meet-team-sec{
    background: #ebe6e0 !important;
    padding: 40px 0;
}

.meet-team-sec img{
  border-radius: 8px;
}

.team-card-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0;
}
.team-card-container .card {
  position: relative;
  width: 17rem;
  height: 340px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-card-container .card .content {
  position: absolute;
  bottom: -190px;
  width: 100%;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  flex-direction: column;
  backdrop-filter: blur(15px);
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: bottom 0.5s;
  transition-delay: 0.65s;
}
.team-card-container .card:hover .content {
  bottom: 0;
  transition-delay: 0s;
}
.team-card-container .card .content .contentBx h3 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin: 20px 0 15px;
  line-height: 1.1em;
  transition: 0.5s;
  transition-delay: 0.6s;
  opacity: 0;
  transform: translateY(-20px);
}
.team-card-container .card:hover .content .contentBx h3 {
  opacity: 1;
  transform: translateY(0);
}
.team-card-container .card .content .contentBx h3 span {
  font-size: 12px;
  font-weight: 400;
  text-transform: initial;
  color: #fff;
}
.team-card-container .card .content .sci {
  position: relative;
  bottom: 10px;
  display: flex;
  padding-left: 0 !important;
}
.team-card-container .card .content .sci li {
  list-style: none;
  margin: 0 10px;
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
  transition-delay: calc(0.2s * var(--i));
}

.team-card-container .card:hover .content .sci li {
  transform: translateY(0);
  opacity: 1;
}
.team-card-container .card .content .sci li a {
  color: white;
  font-size: 24px;
}
.team-card-container .card .content .sci li a img{
  filter: invert(54%) sepia(76%) saturate(313%) hue-rotate(359deg) brightness(102%) contrast(86%);
}
.block-arrow{
  filter: brightness(0);
}

.arow-know-more{
  margin: 2px 0 0 0;
}

.team-container-wrapper{
  display: flex;
  gap: 60px;
  align-items: center;
}

.team-left-content{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: self-start;
}

.team-left-content h2{
  font-size: 40px;
  font-weight: 600;
}

.team-left-content p{
  text-align: left;
  margin: 20px 0 40px;
}

.team-card-container{
  width: 60%; 
}

/*  */
.profile-cards-container {
  /* background-color: #fff; */
  width: fit-content !important;
  margin: auto;
  gap: 30px;
  padding: 100px 0;
}
.profile-card-main .imgBx {
  width: 100%;
  height: 370px;
  /* border-radius: 50%; */
  margin: auto;
  overflow: hidden;
}
.profile-card-main .imgBx img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background: #5a5a5a;
  border-radius: 0;
}
.profile-card-main {
  width: 25%;
  padding: 0px 0 0px 0;
  background-color: #ffffff00;
  border-radius: 0px;
  cursor: pointer;
}
.profile-card-main .profile-content h2 {
  margin-top: 15px;
  color: #C79A4F;
  font-size: 22px;
  margin-bottom: 0;
}
.profile-card-main .profile-content span {
  font-size: 15px;
  color: #000;
}
.profile-card-main .social-icons ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0px;
}
.profile-card-main .social-icons ul a {
  background-color: unset;
  width: 36px;
  height: 36px;
}
.profile-card-main .social-icons ul a img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(332deg) brightness(99%) contrast(106%);
  width: 100% !important;
  height: 100%;
  object-fit: contain;
}
.profile-card-main:hover {
  background-color: #95846600;
  transition: all 0.3s ;
}
.profile-card-main:hover .imgBx {
  transition: all 0.3s ease-in-out;
}
.profile-card-main:hover .profile-content h2 {
  transition: all 0.3s ease-in-out;
}
.profile-card-main:hover .profile-content span {
  transition: all 0.3s ease-in-out;
}
.profile-card-main .social-icons {
  visibility: hidden;
  margin-top: -45px;
  background: #000000b5;
  position: relative;
  z-index: 999;
  padding: 4px 0;
}
.profile-card-main:hover .social-icons {
  visibility: visible;
  transition: all 0.3s ease-in-out;
}
.profile-card-main .social-icons ul a:hover {
  scale: 1.2;
  transition: all 0.3s ease-in-out;
}

.profile-card-main:hover .social-icons ul a img{
  /* filter: brightness(0) saturate(100%) invert(14%) sepia(2%) saturate(2%) hue-rotate(22deg) brightness(94%) contrast(86%); */
}

.profile-content{
  padding-bottom: 20px;
}

.meet-team-sec-tw.meet-our-team-on-page-indiv .container-main{
  max-width: 98% !important;
}

.meet-team-sec-tw.meet-our-team-on-page-indiv .container-main .team-card-container-indi{
  width: 60% !important;
}

.meet-team-sec-tw.meet-our-team-on-page-indiv .team-card-container .card{
  width: 30%;
  height: 390px;
}

.meet-team-sec-tw.meet-our-team-on-page-indiv .team-card-container .card .imgBx {
  height: 300px;
  overflow: hidden;
  width: 98%;
}

.meet-team-sec-tw.meet-our-team-on-page-indiv .team-card-container .card .imgBx img{
  width: 100%;
}

@media (max-width:991px){
  .profile-cards-container {
      flex-wrap: wrap !important;
  }
  .profile-card-main {
      width: 35%;
  }
  .profile-card-main .imgBx {
    width: 96% !important;
}
}

@media (max-width:768px){
  .profile-card-main {
    width: 80%;
}
.profile-cards-container {
  border: unset;
  row-gap: 30px;
}
}

@media (max-width:480px){
  .profile-card-main {
    width: 100%;
}
}


/*  */


.profile-card{
  position: relative;
  width: 280px;
  height: 280px;
  /* width: 220px;
  height: 220px; */
  background: #fff;
  /* padding: 30px; */
  padding: 23px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: .6s;
  margin: 0 ;
}
.profile-card:hover{
  border-radius: 10px;
  height: 320px;
}
.profile-card .img{
  position: relative;
  width: 100%;
  height: 100%;
  transition: .6s;
  z-index: 99;
}
.profile-card:hover .img{
  /* transform: translateY(-60px); */
  transform: translateY(-140px);
}
.profile-card .img img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: .6s;
  height: 100%;
  object-fit: cover;
  background: #2f2f2f;
}
.profile-card:hover img{
  border-radius: 10px;
}
.caption{
  text-align: center;
  transform: translateY(-160px);
  opacity: 0;
  transition: .6s;
}
.profile-card:hover .caption{
  opacity: 1;
}
.caption h3 {
  font-size: 25px;
  font-weight: 600;
  color: #000;
  width: fit-content;
  margin-top: 30px;
  letter-spacing: 0 !important;
  line-height: 34px;
  width: 100%;
}
.caption p {
  font-size: 18px;
  color: #000;
  margin: 2px 0 9px 0;
}
.caption .social-links a{
  color: #333;
  margin-right: 15px;
  font-size: 21px;
  transition: .6s;
}
.social-links a:hover{
  color: #0c52a1;
}

.caption .social-links ul.sci {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 17px 0;
  justify-content: center;
}

.profile-card:hover .caption ul.sci img {
  filter: brightness(0) saturate(100%) invert(13%) sepia(9%) saturate(8%) hue-rotate(322deg) brightness(95%) contrast(84%);
}



@media (max-width:1024px){
  .profile-cards-container.profile-cards-container-fr-creative {
    flex-wrap: wrap;
    column-gap: 110px;
    row-gap: 110px;
}
}

@media (max-width:600px){
  .profile-cards-container.profile-cards-container-fr-creative {
    row-gap: 150px;
}
}

