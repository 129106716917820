.step-form-wrapper{
    padding: 80px 0;
    background: #cdcdcd;
}

.second-sec-txt-wrap{
    width: 100%;
}

.error-message{
    position: absolute;
    bottom: -23px;
    color: #cd2e2e;
    font-size: 13px;
    left: 0;
    font-weight: 500;
}
.email-phone-error{
    right: 19%;
    left: auto !important;
}

.second-sec-txt-wrap h2{
    color: #000;
    font-size: 42px;
    font-weight: 600;
    position: relative;
    padding-bottom: 0px;
    letter-spacing: 1.5px;
}

.second-sec-txt-wrap h2:after {
    content: '';
    position: absolute;
    width: 54px;
    height: 4px;
    background: #C79A4F;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.second-sec-txt-wrap p{
    color: #000;
    font-size: 17px;
    font-weight: 400;
    width: 75%;
    margin: 29px auto 0;
}

.form-wrapper{
    margin-top: 60px;
}

.form-find-wrapper{
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.form-find-wrapper-left{
    width: 70%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0;
    background-image: url('../assets/images/find-form-bg-1.svg'); 
}

.form-find-wrapper-right{
    width: 30%;
    background-color: #A7792E;
}

.form-find-wrapper-right img{
    width: 100%;
    height: 38rem;
    object-fit: cover;
}

.form-wrapper form{
    width: 95%;
    padding: 28px 45px 0;
    border-radius: 16px;
    border: 1px solid #FFF;
    background: rgba(255, 255, 255, 0.59);
    backdrop-filter: blur(9px);
}


.submit-wrapper{
    position: relative;
    bottom: -14px;
    display: flex;
    justify-content: center;
}

form .btm-input-sec{
    width: 100%;
    display: flex;
    gap: 14px;
    margin-bottom: 25px;
    position: relative;
}

form .btm-input-sec input{
    width: 100%;
    border-radius: 5px;
    background: #000;
    border: none;
    padding: 11px 12px;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
}

form .btm-input-sec input::placeholder{
    opacity: 1;
    color: #fff;
}

.button-arrow{
    display: flex;
    align-items: center;
    gap: 15px;
    border: none;
    border-radius: 4px;
    background: #A7792E;
    padding: 11px 27px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.arrow-n-btn{
    margin-bottom: 3px;
}

form .acordian-wrapper{
    margin-bottom: 30px;
}

.accordian-radio-wraper{
    display: flex;
    gap: 15px;
}

.radio-wrapper{
    display: flex;
    gap: 7px;
    align-items: center;
}

.radio-wrapper label{
    line-height: 20px;
    color: #000;
    font-size: 16px;
}

.radio-wrapper label, .radio-wrapper input{
    cursor: pointer;
}

.accordion .accordion-item, .accordion .accordion-button {
    background-color: transparent;
    border: none;
}

.accordion .accordion-button button:focus{
    box-shadow: none !important;
    border: none !important;
}

.accordion .accordion-item{
    border-bottom: 1px solid rgba(0, 0, 0, 0.18);
}

.accordion .accordion-button{
    background-color: transparent;
    border: none;
    color: #000;
    font-weight: 600;
    font-size: 17px;
    padding: 16px 0;
}

.accordion-item:first-of-type .accordion-button{
    background-color: transparent;    
    box-shadow: none;
    color: #000;
}

.accordion-button:focus{
    box-shadow: none !important;
}

.accordion-button:not(.collapsed){
    background: none !important;
    color: #000 !important;
}

.accordion-item .accordion-body{
    padding: 15px 0;
    text-align: left;
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.accordion-button::after{
    background: #A7792E;
    border-radius: 50px;
    width: 28px !important;
    height: 28px !important;
    background-position: center;
    color: #fff;
    background-size: 50% !important;
    background-image: url('../assets/images/arrow-open.svg') !important;
    background-repeat: no-repeat;
}

.form-container-div, .form-submit-message{
    height: 100%;
}

.form-submit-message{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-submit-message p{
    color: #000;
    font-size: 21px;
    font-weight: 600;
}

.tick-img{
    width: 7rem;
    margin-bottom: 30px;
}