.heade-for-inner-listing{
    padding: 140px 0 40px;
    background-image: url('../../assets/images/bg-small-banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.heade-for-inner-listing .black-overlay{
    background: #4e2f00b0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
}

.inner-header-cntnt{
    position: relative;
    z-index: 9;
}